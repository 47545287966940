<template>

  <div class="container-fluid py-5 content-container">
    <div class="cover" v-if="submitting || submitSucceeded || submitFailed">
      <span class="spinner" v-if="submitting"><img src="../assets/spinner.svg"></span>
      <span class="submitsuccess" v-if="submitSucceeded">Vielen Dank für Ihre Anmeldung!</span>
      <span class="submiterror" v-if="submitFailed">{{ submitError }}</span>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-lg-9 col-xl-7">
        <div class="container px-0">
          <div class="row">
            <div class="col-12 pb-4">
              <p>
                Bitte tragen Sie im E-Mail Adressfeld Ihre E-Mail Adresse ein, damit wir Sie auf der AOS-Informationsplattform anmelden können.
              </p>
              <p>
                Freischaltung erfolgt innerhalb von 2 Werktagen.
              </p>
            </div>

            <form method="post" @submit.prevent="formSubmit">

              <div class="row justify-content-center">
                <div class="col-12 col-sm-8 pb-2">
                  <input v-model="v$.form.email.$model" type="email" :class="inputClass(v$.form.email.$error)" placeholder="E-Mail-Adresse" aria-label="E-Mail" id="i_email">
                  <div class=" error" v-if="v$.form.email.$error && formInvalid">Ungültige E-Mail-Adresse</div>
                </div>
              </div>
              <div class="row justify-content-start pt-4">
                <div class="col-12 pb-2">

                  <div class="boxed">
                    <div class="form-check form-check-inline">
                      <input v-model="v$.form.terms_accepted.$model" class="form-check-input" type="checkbox" id="i_terms_accepted" name="terms_accepted" value="1">
                      <label class="form-check-label" for="i_terms_accepted">
                        Die <a href="https://consultant-net.de/de/datenschutz" target="_blank">Datenschutzhinweise</a> habe ich gelesen und
                        akzeptiert.
                      </label>
                    </div>
                    <div class="error" v-if="v$.form.terms_accepted.$error && formInvalid">Bitte auswählen zum Fortfahren</div>
                  </div>

                </div>
              </div>

              <div class="row justify-content-center pt-4">
                <div class="col text-center">
                  <button class="btn btn-lg btn-outline-primary" :disabled="!form.terms_accepted || submitting" @click="">
                    {{ submitting? 'Moment...': 'Anmelden' }}
                  </button>
                </div>
              </div>
            </form>


          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

const ISDEV = false
//const API_URL = "https://aos-be.cometo.dev"
const API_URL = "https://anmeldung.bmw-aos-de.com/api"

import { ref, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, maxLength, minValue, maxValue, email, numeric, minLength } from '@vuelidate/validators'
import pdf from "../assets/datenschutz.pdf"

const mustBeTrue = (v) => v == true


export default {
  name: 'PersonalData',
  mounted() {
    if (ISDEV) {
      this.form = {
        email: "mhilbig@outlook.com",
        terms_accepted: true,
      }
    }


  },
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    form: {
      email: "",
      terms_accepted: false,
    },
    modulesSelected: [],
    terms_pdf: pdf,
    formInvalid: false,
    submitting: false,
    submitSucceeded: false,
    submitFailed: false
  }),
  validations() {
    return {
      form: {
        email: { required, email },
        terms_accepted: { mustBeTrue }
      }
    }
  },
  computed: {

  },
  methods: {
    inputClass: (err) => err ? 'is-invalid form-control' : 'form-control',
    formSubmit: async function (e) {
      const valid = await this.v$.form.$validate()
      this.formInvalid = !valid

      if (valid) {
        this.submitting = true

        const fd = new FormData()
        for (const [field, value] of Object.entries(this.form)) {
          fd.append(field, value)
        }
        const response = await fetch(API_URL + "/data", {
          method: "POST",
          body: fd
        })
        this.submitting = false
        if (response.status != 200) {
          const result = await response.json()
          this.submitError = result.msg
          this.submitFailed = true
          window.setTimeout(() => { this.submitFailed = false }, 2000)
        }
        else {
          this.form = {}
          this.submitting = false
          this.formInvalid = false
          this.v$.$reset()
          this.submitSucceeded = true
          window.setTimeout(() => { this.submitSucceeded = false }, 3000)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@include primary-button;
@include page-header;
@include input-label;


.submiterror {
  font-size: 150%;
  color: #fc5454;
  font-weight: 400;
}

.submitsuccess {
  font-size: 150%;
  color: #138ce8;
  font-weight: 400;
}

.content-container {
  position: relative;
  background: #00000090;
  color: #fff;
}

.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: #000000DD;
  display: grid;
  place-items: center;
  color: #000;
}

.error {
  color: #fc5454;
  font-size: 90%;
}

.boxed {
  background: #222;
  padding: 1rem;
  border-radius: 4px;
}

a {

  &:link,
  &:active,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}
</style>
