<template>
  <div id="app-container">
    <div class="d-flex align-items-center min-vh-100">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-2 py-4 align-self-center text-center">
            <img src="./assets/bmwlogo.png" class="header-img">
          </div>
          <div class="col-12 col-lg-10 align-self-center text-center text-lg-start">
            <div class="header-text">
              Anmeldung zur AOS-Informationsplattform
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col px-0">

            <PersonalData @submit-error="handleSubmitError"></PersonalData>


          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import PersonalData from './components/PersonalData.vue'

export default {
  name: 'App',
  components: {
    PersonalData
  },
  data: () => ({
    submitting: false,
    submitError: false,
    submitSucceeded: false
  }),
  methods: {
    handleSubmitError(e) {
      this.submitError = true
      console.log(e)
    },
    handleSubmitSuccess(e) {
      this.submitError = false
    },
    handleSubmitting() {
      this.submitting = true
    }
  }
}
</script>

<style lang="scss" scoped>
@include page-header;

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./fonts/open-sans-v28-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/open-sans-v28-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./fonts/open-sans-v28-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/open-sans-v28-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('./fonts/open-sans-v28-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/open-sans-v28-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


#app-container {
  background: url(./assets/bmw-bg.jpg);
  background-size: cover;
  background-position: center;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
}


.header-row {
  background: #333;
}

.header-text {
  font-weight: 400;
  font-size: 120%;
  color: #fff;

  @include media-breakpoint-up(md) {
    font-size: 150%;
  }
  @include media-breakpoint-up(lg) {
    font-size: 200%;
  }


}

.header-img {
  width: 180px;
}
</style>
